<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Monitor from "@/services/Monitor";
    import Swal from "sweetalert2";
    import ModalEditRbacAccess from "@/components/modals/monitor/modalEditRbacAccess";
    import ModalAddNewRbacAccess from "@/components/modals/monitor/modalAddNewRbacAccess" ;


    export default {
        components: { Layout, PageHeader , ModalEditRbacAccess , ModalAddNewRbacAccess },
        data() {
            return {
                showLoader: false,
                error: null,
                tableData: [],
                title: "RBAC Access",
                currentPage: 1,
                perPage: 10,
                items: [] ,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "version",
                isUpdating: false,
                sortDesc: true,
                isTableLoading: false,
                modalData : {} ,
                editData : {} ,
                roles : [],
                fields: [
                    {
                        key: "id",
                        label: "Id",
                        visible: false,
                    },
                    {
                        key: "http_verb",
                        label: "Http",
                        visible: true,
                    },
                    {
                        key: "method",
                        label: "Method",
                        visible: true,
                    },
                    {
                        key: "type",
                        label: "Type",
                        visible: true,
                    },
                    "edit" ,
                    "delete"

                ],
                filterInput: {
                    role: '',
                },
                totalRows: 0,
                showModal: false,
                selectedRow: null,

            };
        },
        async mounted() {
            try {
                await this.getRbacAccess();
            } catch (error) {
                console.error('Error during component initialization:', error);
            }
        },
        methods: {

            async getRbacAccess() {
                this.showLoader = true;
                try {
                    this.isTableLoading = true;
                    const response = await Monitor.getRbacAccess();
                    this.tableData = response.data.data;
                    this.totalRows = this.tableData.length;
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = [];
                    this.totalRows = 0;
                } finally {
                    this.showLoader = false;
                    this.isTableLoading = false;
                }
            },

            async deleteRbacAccess(id) {
                try {
                    const response = await Monitor.deleteRbacAccess(id);
                    if (response.data.delete) {
                        this.getRbacAccess();
                        Swal.fire('Success', 'RBAC access deleted successfully', 'success');
                    } else {
                        Swal.fire('Error', 'Failed to delete RBAC access', 'error');
                    }
                } catch (error) {
                    Swal.fire('Error', 'Failed to delete RBAC access', 'error');
                    console.error('Error deleting RBAC access:', error);
                }
            },

            callmodalEditRbacAccess(item){
                this.editData = { ...item };

                this.$bvModal.show("edit_rbac_access")
            },
            callModalAddNewRbacAccess() {
                this.$bvModal.show("add_new_rbac_access");
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
        },
        middleware: "authentication",
    };
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row" v-if="!showLoader">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">

                        <div class="row mb-3">
                            <div class="col-md-6">
                                <div >
                                    <a  href="javascript:void(0);" class="btn btn-success waves-effect waves-light" @click="callModalAddNewRbacAccess">
                                        <i class="mdi mdi-plus me-2"></i>
                                        Add New
                                    </a>
                                </div>
                            </div>
                        </div>


                        <div class="col-sm-12 col-md-12">
                            <div class="dataTables_filter text-md-center">
                                <label class="d-inline-flex align-items-center">
                                    Search:
                                    <b-form-input
                                            v-model="filter"
                                            type="search"
                                            placeholder="Search..."
                                            class="form-control form-control-sm ms-2"
                                            style="width: 300px;"
                                    ></b-form-input>
                                </label>
                            </div>
                        </div>
                        <div class="table-responsive mb-0">
                            <b-table
                                :busy="isTableLoading"
                                :items="tableData"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                show-empty
                                empty-text="No Data Found"
                                @filtered="onFiltered"
                            >
                                <template v-slot:cell(edit)="data">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a href="javascript:void(0);" class="px-2 text-primary" v-b-tooltip.hover title="Edit" @click="callmodalEditRbacAccess(data.item)">
                                                <i class="uil uil-pen font-size-18"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </template>
                                <template v-slot:cell(delete)="data">
                                    <button @click="deleteRbacAccess(data.item.id)" class="btn btn-danger btn-sm">
                                        Delete
                                    </button>
                                </template>


                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <b-pagination
                                            v-model="currentPage"
                                            :total-rows="totalRows"
                                            :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="card-body d-flex align-items-center justify-content-center">
                <b-spinner large></b-spinner>
            </div>
        </div>
        <ModalEditRbacAccess @onRefresh="getRbacAccess" :editData="editData"></ModalEditRbacAccess>
        <ModalAddNewRbacAccess @onRefresh="getRbacAccess()"></ModalAddNewRbacAccess>



    </Layout>
</template>
