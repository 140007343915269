<script>
    import Monitor from '@/services/Monitor';
    import validationMessages from '@/components/validations';
    import { required, maxLength } from "vuelidate/lib/validators";

    export default {
        components:{
            validationMessages
        },
        data() {
            return {
                showLoader: false,
                showModal: false,
                tryingToSubmit: false,
                submitted: false,
                httpVerbs: ['GET','HEAD','POST','PUT','DELETE','CONNECT','OPTIONS','TRACE','PATCH'],
                typeOptions: ["public", "secured", "unsecured"],
                method : '' ,
                httpVerb : '' ,
                typeOption: ''

            };
        },
        validations: {
           httpVerb : {
                required,
            },
            method: {
                required,
            } ,
            typeOption : {
               required  ,
            }
        },
        methods: {
            refreshData() {
                this.$emit('onRefresh')
            },
            resetProps(){
                this.submitted = false

            },
            closeModal() {
                this.showModal = false;
            },
            addNewRbacAccess() {
                this.tryingToSubmit = true;
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid ) {
                    this.tryingToSubmit = false;
                    return;
                } else {

                    const formData = {
                        http_verb: this.httpVerb,
                        method: this.method,
                        type: this.typeOption,
                    };

                            Monitor.createRbacAccess(formData)
                                .then((response) => {
                                    const res = response.data.data ? response.data.data : false;
                                    const error = response.data.error ? response.data.error : '';
                                    if (res) {
                                        this.successmsg('RBAC Access Created!');
                                        this.refreshData();
                                        this.closeModal();
                                    } else {
                                        this.failedmsg('Failed to create new RBAC Access!')
                                    }
                                })
                                .catch(error => {
                                    const resData = error.response.data.error ? error.response.data.error : 'Failed';
                                    const errorMsg = Array.isArray(resData) ? resData[0] : resData;
                                    this.failedmsg(errorMsg)
                                })
                                .finally(() => {
                                    this.tryingToSubmit = false;
                                });

                }
            },
}

    }
</script>

<template>
    <b-modal  id="add_new_rbac_access" v-model="showModal" @hidden="resetProps" title="Add New Rbac Access" title-class="font-18" size="md">
        <form @submit.prevent="addNewRbacAccess" v-if="!showLoader">
            <div class="form-group">
                <div class="form-group">
                    <label for="http_verb">HTTP Verb:</label>
                    <select id="http_verb" class="form-control" v-model="httpVerb">
                        <option v-for="httpVerb in httpVerbs" :key="httpVerb" :value="httpVerb"
                                :class="{ 'is-invalid': submitted && $v.httpVerb.$error }"
                        >
                            {{ httpVerb }}
                        </option>
                    </select>
                    <validationMessages v-if="submitted" :fieldName="'HTTP Verb'" :validationName="$v.httpVerb"></validationMessages>
                </div>


                <b-form-group label="Method" label-for="method" class="mt-4">
                    <b-form-input
                            v-model.trim="method"
                            type="text"
                            id="method"
                            aria-describedby="method-feedback"
                            :class="{ 'is-invalid': submitted && $v.method.$error, }"
                    >
                        <validationMessages v-if="submitted" :fieldName="'Method'" :validationName="$v.method"></validationMessages>

                    </b-form-input>
                </b-form-group>

                <div class="form-group mt-4">
                    <label for="type">Type:</label>
                    <select id="type" class="form-control" v-model="typeOption">
                        <option v-for="typeOption in typeOptions" :key="typeOption" :value="typeOption">
                            {{ typeOption }}
                        </option>
                    </select>
                    <validationMessages v-if="submitted" :fieldName="'Type Option'" :validationName="$v.typeOption"></validationMessages>
                </div>
            </div>

        </form>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addNewRbacAccess" :disabled="tryingToSubmit || showLoader">
                <b-spinner v-show="tryingToSubmit" small></b-spinner>
                Submit
            </b-button>
        </template>
    </b-modal>
</template>
