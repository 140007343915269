<script>
    import Monitor from "@/services/Monitor";

    export default {
        props: {
            editData: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                csrf_token: localStorage.getItem("csrf_token"),
                typeOptions: ["public", "secured", "unsecured"],
                httpVerbs: ['GET','HEAD','POST','PUT','DELETE','CONNECT','OPTIONS','TRACE','PATCH'],
                tryingToEdit: false,
                showModal: false,
                // Create a local copy of editData
                localEditData: this.editData ? { ...this.editData } : {},
            };
        },
        watch: {
            editData: {
                immediate: true,
                handler(newValue) {
                    this.localEditData = newValue ? { ...newValue } : {};
                },
            },
        },
        methods: {
            refreshData() {
                this.$emit('onRefresh');
            },
            closeModal() {
                this.localEditData = {};
                this.showModal = false;
            },
            async updateRbacAccess() {
                this.tryingToEdit = true;

                try {
                    const id = this.localEditData.id;

                    const response = await Monitor.updateRbacAccess(id, this.localEditData);
                    const res = response.data.data ? response.data.data : false;
                    const error = response.data.error ? response.data.error : '';

                    if (res) {
                        this.successmsg('RBAC Access successfully edited');
                        this.tryingToEdit = false;
                        this.closeModal();
                        this.refreshData();
                    } else {
                        this.failedmsg('Failed to edit RBAC Access!');
                    }
                } catch (error) {
                    const resData = error.response.data.error ? error.response.data.error : 'Failed';
                    const errorMsg = Array.isArray(resData) ? resData[0] : resData;
                    this.failedmsg(errorMsg);
                } finally {
                    this.tryingToEdit = false;
                }
            },
        },
    };
</script>
<template>
    <b-modal v-model="showModal" id="edit_rbac_access" title="Edit RBAC Access" title-class="font-18" centered size="md">
        <form @submit.prevent="updateRbacAccess">
            <div class="row">
                <div class="form-group">
                    <label for="httpVerb">HTTP Verb:</label>
                    <select id="httpVerb" class="form-control" v-model="localEditData.http_verb">
                        <option v-for="verb in httpVerbs" :key="verb" :value="verb">{{ verb }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="method">Method:</label>
                    <input type="text" id="method" class="form-control" v-model="localEditData.method" />
                </div>
                <div class="form-group">
                    <label for="type">Type:</label>
                    <select id="type" class="form-control" v-model="localEditData.type">
                        <option v-for="typeOption in typeOptions" :key="typeOption" :value="typeOption">
                            {{ typeOption }}
                        </option>
                    </select>
                </div>
            </div>
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="updateRbacAccess" :disabled="tryingToEdit">Edit
                <b-spinner v-show="tryingToEdit" small></b-spinner>
            </b-button>
        </template>
    </b-modal>
</template>
